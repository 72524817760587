<template>
<div>
  <text-input  
    classes="is-rounded is-medium"
    required 
    v-model="label"
    :error="$root.errors['handler_payload.label']">
    Label
  </text-input>
  <p class="help is-danger">
    NOTE: Date fields are not taken into account when assessing a checklist for success or failure.
  </p>
</div>  
</template>
<script>
import { mapGetters } from 'vuex'
import { DatePickerCheckpoint } from '@/store/models/Checkpoint'

export default {
  
  data: () => (new DatePickerCheckpoint()),

  created() {
    if(this.checkpoint.id) {
      this.label = new DatePickerCheckpoint(this.checkpoint.handler_payload.label)
    }
    this.updateLabel()
  },

  methods: {
    updateLabel() {
      this.$store.commit('checkpoint/setHandlerPayload', { label: this.label })
    }
  },

  computed: {
    ...mapGetters('checkpoint', [
      'checkpoint'
    ])
  },

  watch: {
    'label': 'updateLabel',
  }

}
</script>